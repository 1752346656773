import {mdiAccount, mdiClose} from "@mdi/js";
import Icon from "@mdi/react";
import {useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {IZoneData} from "../../../typings/IZoneData";
import {SeriesType} from "../../../typings/SeriesType";
import {Formatter} from "../../../utils/formatter";
import {GlanceIndicatorSmall} from "../GlanceIndicatorSmall/GlanceIndicator";
import {getStatIcon, SmallStatCategory} from "../GlanceStatsSmall/GlanceStatsSmall";
import styles from "./AnalyticsPanel.module.scss";
import {forceSeriesColors, StandardGraphs} from "./StandardGraphs/StandardGraphs";
import {TrainingMap} from "./TrainingMap/TrainingMap";
import {SizedBox} from "../../base/SizedBox/SizedBox";
import ResponsiveContext, {ScreenType} from "../../../contexts/ResponsiveContext";
import {isRowing, isTwoPaddle} from "../../../typings/TrainingSport";
import Highcharts from "highcharts";
import {ITrainingCommon} from "../../../typings/ITrainingCommon";
import {max} from "../../../utils/arrayUtils";
import {ChartSelector} from "./ChartSelector/ChartSelector";
import {
    ChartSelectorDialog,
    getAvailableSeries,
    getDefaultSeries
} from "../../dialogs/ChartSelectorDialog/ChartSelectorDialog";
import DialogContext from "../../../contexts/DialogContext";
import {ITrainingCommonTraining} from "../../../typings/ITrainingCommonTraining";
import {DetailedPaddleForceGraph} from "./DetailedPaddleForceGraph/DetailedPaddleForceGraph";
import {ChartStepper} from "../../base/ChartStepper/ChartStepper";
import {useAnalyticsPanelSelection} from "./UseAnalyticsPanelSelection";
import {speedToTempo, speedToTempo500} from "../../../utils/unitHelpers";
import {ImuGraph} from "./ImuGraph/ImuGraph";
import {ITrainingCommonFile} from "../../../typings/ITrainingCommonFile";
import {Modal} from "reactstrap";
import {ChartDialog} from "../../dialogs/ChartDialog/ChartDialog";

interface IAnalyticsPanel {
    trainingCommon: ITrainingCommon;
    trainingCommonFile: ITrainingCommonFile | null;
    closable: boolean,
    showSettingsFirst?: boolean,
    onSelectionChange?: (value: ISlice | null) => void,
    onClose?: () => void,
    onSelectedTrainingsChanged?: (trainings: ITrainingCommonTraining[]) => void,
    panelNumber?: number,
    heartRateMax: number,
    speedMax: number,
    strokeRateMax: number,
    distancePerStrokeMax: number;
    pullingForceMax: number,
}

const hrZoneColors5 = [
    "rgba(153, 255, 153, 0.6)",
    "rgba(204, 255, 153, 0.6)",
    "rgba(255, 255, 153, 0.6)",
    "rgba(255, 204, 153, 0.6)",
    "rgba(255, 153, 153, 0.6)",
];

const hrZoneColors7 = [
    "rgba(153, 255, 153, 0.6)",
    "rgba(204, 255, 153, 0.6)",
    "rgba(236,255,153,0.6)",
    "rgba(255,231,153,0.6)",
    "rgba(255,184,115,0.71)",
    "rgba(255,140,104,0.75)",
    "rgba(255,151,151,0.8)",
];

const barColor = [
    "rgba(153, 255, 153, 0.6)",
    "rgba(204, 255, 153, 0.6)",
    "rgba(255, 255, 153, 0.6)",
    "rgba(255, 204, 153, 0.6)",
    "rgba(255, 153, 153, 0.6)",
];

export interface ISlice {
    startMs: number;
    endMs: number;
}

interface IZoneDescriptor {
    name: string;
    tooltip: string;
    min: number;
    max: number;
    color: string;
}

const getZones = (arr: number[], zones: IZoneDescriptor[] = []): IZoneData[] => {
    const zonesDataCounters = zones.map(z => ({...z, count: 0}));
    for (let i = 0; i <= arr.length; i += 1) {
        const val = arr[i];
        for (const zoneDataCounter of zonesDataCounters) {
            if (val >= zoneDataCounter.min && val < zoneDataCounter.max) {
                zoneDataCounter.count += 1;
                break;
            }
        }
    }

    return zonesDataCounters.map(z => ({
        name: z.name,
        tooltip: z.tooltip,
        value: (z.count * 100) / arr.length,
        color: z.color,
    }));
};

export const AnalyticsPanel = ({
                                   trainingCommon,
                                   trainingCommonFile,
                                   closable,
                                   showSettingsFirst,
                                   onSelectionChange,
                                   onClose,
                                   onSelectedTrainingsChanged,
                                   panelNumber,
                                   heartRateMax,
                                   speedMax,
                                   strokeRateMax,
                                   distancePerStrokeMax,
                                   pullingForceMax
                               }: IAnalyticsPanel) => {
    const {t} = useTranslation();
    const {screenType} = useContext(ResponsiveContext);
    const {openDialog} = useContext(DialogContext);
    const [charts, setCharts] = useState(getDefaultSeries(trainingCommon));
    const enabledCharts = useMemo(() => getAvailableSeries(trainingCommon), [trainingCommon]);
    const [syncDetailedForceView, setSyncDetailedForceView] =
        useState(true);

    const settingsShown = useRef(false);
    useEffect(() => {
        if (showSettingsFirst && !settingsShown.current) {
            settingsShown.current = true;
            openDialog(<ChartSelectorDialog initialCharts={charts} enabledCharts={enabledCharts}
                                            initialSyncDetailedForceView={syncDetailedForceView} onDone={
                (charts, syncDetailedForceView) => {
                    setCharts(charts);
                    setSyncDetailedForceView(syncDetailedForceView);
                }
            }/>);
        }
    }, [charts, enabledCharts, openDialog, showSettingsFirst, syncDetailedForceView]);

    const [selectedTrainingIndexes, setSelectedTrainingIndexes] =
        useState<number[]>([...trainingCommon.trainings.keys()]);
    const toggleSelectedTraining = useCallback((index: number) => {
        if (selectedTrainingIndexes.includes(index)) {
            if (selectedTrainingIndexes.length > 1)
                setSelectedTrainingIndexes(x => x.filter(y => y !== index));
        } else
            setSelectedTrainingIndexes(x => [...x, index]);
    }, [selectedTrainingIndexes]);
    const selectedTrainings = useMemo(() =>
            selectedTrainingIndexes.map(x => trainingCommon.trainings[x])
        , [trainingCommon, selectedTrainingIndexes]);

    useEffect(() => {
        onSelectedTrainingsChanged?.(selectedTrainings);
    }, [onSelectedTrainingsChanged, selectedTrainings]);

    const detailedLeftForces = useMemo(() =>
        trainingCommon.trainings.map((x) => x.detailedLeftPaddlingForceNSeries &&
            selectedTrainings.includes(x) ?
                x.detailedLeftPaddlingForceNSeries : null
        ), [selectedTrainings, trainingCommon.trainings]);

    const detailedRightForces = useMemo(() =>
        trainingCommon.trainings.map((x) => x.detailedRightPaddlingForceNSeries &&
            selectedTrainings.includes(x) ?
                x.detailedRightPaddlingForceNSeries : null
        ), [selectedTrainings, trainingCommon.trainings]);

    const hasSomeDetailedForces = useMemo(() =>
            detailedLeftForces.some(x => x) || detailedRightForces.some(x => x),
        [detailedLeftForces, detailedRightForces]);
    const showDetailedChart = charts.includes(SeriesType.DETAILS) && hasSomeDetailedForces;

    const isTracker = useMemo(() =>
        trainingCommon.recorderDevice?.type === "tracker", [trainingCommon.recorderDevice]);

    const seriesLength = useMemo(() => {
        if (trainingCommon.strokeData?.length)
            return trainingCommon.strokeData?.length;
        const fallbackLength = trainingCommon.distanceData?.length ??
            trainingCommon.gpsData?.length ??
            trainingCommon.speedData?.length ?? 0;
        if (isTracker)
            return Math.floor(fallbackLength / 10);
        return Math.floor(fallbackLength);
    }, [trainingCommon, isTracker]);
    const [sliceStart, setSliceStart] =
        useState<number>(0);
    const [detailedSliceStart, setDetailedSliceStart] =
        useState<number>(0);
    const sliceMax = useMemo(() => (seriesLength - 1) * 1000, [seriesLength]);
    const [sliceEnd, setSliceEnd] = useState<number>(sliceMax);
    const [detailedSliceEnd, setDetailedSliceEnd] = useState<number>(sliceMax);
    const [selection, setSelection] =
        useState<[number, number]>([0, sliceMax]);

    const speedSeries = useMemo(() =>
        trainingCommon.speedData ?
            trainingCommon.speedData.map((y, i) => [i * (isTracker ? 100 : 1000), y]) :
            null, [isTracker, trainingCommon.speedData]);
    const distanceSeries = useMemo(() =>
            trainingCommon.distanceData ?
                trainingCommon.distanceData.map((y, i) => [i * (isTracker ? 100 : 1000), y]) : null,
        [isTracker, trainingCommon.distanceData]);
    const tempoSeries = useMemo(() =>
            trainingCommon.tempoData ?
                trainingCommon.tempoData.map((y, i) => [i * (isTracker ? 100 : 1000), y]) : null,
        [isTracker, trainingCommon.tempoData]);
    const tempo500Series = useMemo(() =>
            trainingCommon.tempo500Data ?
                trainingCommon.tempo500Data.map((y, i) => [i * (isTracker ? 100 : 1000), y]) : null,
        [isTracker, trainingCommon.tempo500Data]);
    const strokeSeries = useMemo(() =>
        trainingCommon.strokeData ?
            trainingCommon.strokeData.map((y, i) => [i * 1000, y]) : null, [trainingCommon]);
    const distancePerStrokeSeries = useMemo(() =>
        trainingCommon.distancePerStrokeData ?
            trainingCommon.distancePerStrokeData.map((y, i) => [i * 1000, y]) : null, [trainingCommon]);
    const heartRateSeries = useMemo(() =>
        trainingCommon.trainings.map(x => x.heartRateBpmSeries && selectedTrainings.includes(x) ?
            x.heartRateBpmSeries.map((y, i) => [i * 1000, y]) : null
        ), [trainingCommon, selectedTrainings]);
    const forceLeftSeries = useMemo(() =>
            trainingCommon.trainings.map(x =>
                selectedTrainings.includes(x) && x.leftPaddlingForceNSeries ?
                    x.leftPaddlingForceNSeries.map((y, i) => [i * 1000, y]) : null),
        [trainingCommon.trainings, selectedTrainings]);
    const forceRightSeries = useMemo(() =>
            trainingCommon.trainings.map(x =>
                selectedTrainings.includes(x) && x.rightPaddlingForceNSeries ?
                    x.rightPaddlingForceNSeries.map((y, i) => [i * 1000, y]) : null),
        [trainingCommon.trainings, selectedTrainings]);
    const podBatteryLeftSeries = useMemo(() =>
            trainingCommon.trainings.map(x =>
                selectedTrainings.includes(x) && x.leftBatterySeries ?
                    x.leftBatterySeries.map((y, i) => [i * 1000, y]) : null),
        [trainingCommon.trainings, selectedTrainings]);
    const podBatteryRightSeries = useMemo(() =>
            trainingCommon.trainings.map(x =>
                selectedTrainings.includes(x) && x.rightBatterySeries ?
                    x.rightBatterySeries.map((y, i) => [i * 1000, y]) : null),
        [trainingCommon.trainings, selectedTrainings]);

    const hasSomeForces = useMemo(() =>
            forceLeftSeries.some(x => x) || forceRightSeries.some(x => x),
        [forceLeftSeries, forceRightSeries]);

    const zoom100 = useMemo(() => sliceStart === 0 && sliceEnd === sliceMax,
        [sliceStart, sliceEnd, sliceMax]);

    const zoom100Detailed = useMemo(() => detailedSliceStart === 0 && detailedSliceEnd === sliceMax,
        [detailedSliceStart, detailedSliceEnd, sliceMax]);

    const maxForce = useMemo(() =>
        max(trainingCommon.trainings
            .filter(x => x.maxPaddlingForceN != null)
            .map(x => x.maxPaddlingForceN!)), [trainingCommon]);
    const maxSpeed = useMemo(() =>
        max(trainingCommon.speedData ?? [0]), [trainingCommon]);
    const maxTempo = useMemo(() =>
        max(trainingCommon.tempoData ?? [0]), [trainingCommon]);
    const maxTempo500 = useMemo(() =>
        max(trainingCommon.tempo500Data ?? [0]), [trainingCommon]);
    const trainingDuration = useMemo(() => trainingCommon.endAt.getTime() - trainingCommon.startAt.getTime(),
        [trainingCommon.endAt, trainingCommon.startAt]);
    const {
        hrSlice,
        allForcesSlice,
        speedSlice,
        tempoSlice,
        tempo500Slice,
        sliceStartIndex,
        sliceEndIndex,
        sliceForceAvg,
        sliceForceMax,
        sliceHeartRateAvg,
        sliceHeartRateMax,
        sliceStrokeAvg,
        sliceStrokeMax,
        sliceSpeedAvg,
        sliceSpeedMax,
        sliceTempo500Avg,
        sliceTempo500Max,
        sliceDistance
    } = useAnalyticsPanelSelection(selection,
        syncDetailedForceView ? [detailedSliceStart, detailedSliceEnd] : undefined,
        sliceMax, seriesLength, selectedTrainings, trainingCommon, isTracker);

    const singleHrZones = useMemo(() => {
        const hrZones = trainingCommon.trainings[selectedTrainingIndexes[0]].hrZones;
        return hrSlice.length === 1 ? hrZones.slice(0, -1).map((bpm, i) => ({
            min: bpm,
            max: hrZones[i + 1],
            color: hrZones.length === 6 ? hrZoneColors5[i] : hrZoneColors7[i],
        })) : undefined;
    }, [hrSlice.length, selectedTrainingIndexes, trainingCommon.trainings]);

    const selectedHrZoneData = useMemo(() =>
        singleHrZones ? getZones(hrSlice[0],
            singleHrZones.map((z, i, arr) => ({
                name: t("heartrate zone i", {i: i + 1}),
                tooltip: `${Math.floor(z.min)}-${i + 1 >= arr.length ? Math.floor(z.max) : Math.floor(arr[i + 1].min - 1)}`,
                min: z.min,
                max: z.max + 0.000001,
                color: barColor[i],
            })).reverse()).map(z => ({
            ...z,
            tooltip: `${Formatter.secToTimeString(z.value / 100 * trainingDuration / 1000)}, ${z.tooltip}`,
        })) : undefined, [hrSlice, trainingDuration, singleHrZones, t]);

    const speedZonesData = useMemo(() => getZones(speedSlice,
        [0, 0.2, 0.4, 0.6, 0.8].map((ratio, i, arr) => ({
            name: t("speed zone i", {i: i + 1}),
            tooltip: `${Math.floor(maxSpeed * arr[i])}-${i + 1 >= arr.length ?
                Math.floor(maxSpeed * (arr[i] + 0.2)) :
                Math.floor(maxSpeed * arr[i + 1])}`,
            min: maxSpeed * ratio,
            max: maxSpeed * (ratio + 0.200001),
            color: barColor[i],
        })).reverse()).map(z => ({
        ...z,
        tooltip: `${Formatter.secToTimeString(z.value / 100 * trainingDuration / 1000)}, ${z.tooltip}`,
    })), [maxSpeed, speedSlice, t, trainingDuration]);

    const tempoZonesData = useMemo(() => getZones(tempoSlice,
        [0, 0.2, 0.4, 0.6, 0.8].map((ratio, i, arr) => ({
            name: t("tempo zone i", {i: arr.length - i}),
            tooltip: `${Math.floor(maxTempo * arr[i])}-${i + 1 >= arr.length ?
                Math.floor(maxTempo * (arr[i] + 0.2)) :
                Math.floor(maxTempo * arr[i + 1])}`,
            min: maxTempo * ratio,
            max: maxTempo * (ratio + 0.200001),
            color: barColor[i],
        }))).map(z => ({
        ...z,
        tooltip: `${Formatter.secToTimeString(z.value / 100 * trainingDuration / 1000)}, ${z.tooltip}`,
    })), [maxTempo, t, tempoSlice, trainingDuration]);

    const tempo500ZonesData = useMemo(() => getZones(tempo500Slice,
        [0, 0.2, 0.4, 0.6, 0.8].map((ratio, i, arr) => ({
            name: t("tempo zone i", {i: arr.length - i}),
            tooltip: `${Math.floor(maxTempo500 * arr[i])}-${i + 1 >= arr.length ?
                Math.floor(maxTempo500 * (arr[i] + 0.2)) :
                Math.floor(maxTempo500 * arr[i + 1])}`,
            min: maxTempo500 * ratio,
            max: maxTempo500 * (ratio + 0.200001),
            color: barColor[i],
        }))).map(z => ({
        ...z,
        tooltip: `${Formatter.secToTimeString(z.value / 100 * trainingDuration / 1000)}, ${z.tooltip}`,
    })), [maxTempo500, t, tempo500Slice, trainingDuration]);

    const forceZonesData = useMemo(() => getZones(allForcesSlice, [0.5, 0.6, 0.7, 0.8, 0.9]
        .map((ratio, i, arr) => ({
            name: t("force zone i", {i: i + 1}),
            tooltip: `${Math.floor(maxForce * arr[i])}-${i + 1 >= arr.length ?
                Math.floor(maxForce * (arr[i] + 0.1)) :
                Math.floor(maxForce * arr[i + 1])}`,
            min: maxForce * ratio,
            max: maxForce * (ratio + 0.100001),
            color: barColor[i],
        })).reverse()).map(z => ({
        ...z,
        tooltip: `${Formatter.secToTimeString(z.value / 100 * trainingDuration / 1000)}, ${z.tooltip}`,
    })), [allForcesSlice, trainingDuration, maxForce, t]);

    const focusedTimeRef = useRef<number>(0);
    const previousIndexRef = useRef<number>(0);
    const mapRef = useRef<any>();
    const mapsRef = useRef<any>();
    const markerRef = useRef<any>(undefined);
    useEffect(() => {
        const t = window.setInterval(() => {
            if (!mapRef.current || !mapsRef.current ||
                !charts.includes(SeriesType.GPS) || !trainingCommon.gpsData) return;

            let i = Math.floor(focusedTimeRef.current / (isTracker ? 100 : 1000));
            if (previousIndexRef.current === i) return;
            previousIndexRef.current = i;

            try {
                if (markerRef.current) {
                    markerRef.current.setPosition(trainingCommon.gpsData[i]);
                } else {
                    markerRef.current = new mapsRef.current.Marker({
                        position: trainingCommon.gpsData[i],
                        map: mapRef.current,
                        title: "",
                    });
                }
            } catch (e) {
                console.log(e);
            }
        }, 200);
        return () => clearInterval(t);
    }, [charts, seriesLength, trainingCommon.gpsData, isTracker]);

    useEffect(() => {
        if (!charts.includes(SeriesType.GPS)) previousIndexRef.current = 0;
        if (!charts.includes(SeriesType.GPS)) markerRef.current = undefined;
    }, [charts]);

    useEffect(() => {
        if (selection[0] === 0 && selection[1] === sliceMax)
            onSelectionChange?.(null);
        else
            onSelectionChange?.({startMs: Math.floor(selection[0]), endMs: Math.floor(selection[1])});
    }, [selection, onSelectionChange, sliceMax]);

    const mobileOrTablet = [ScreenType.mobile, ScreenType.tablet].includes(screenType);

    const hasZones = charts.reduce((acc, curr) => acc ||
        [SeriesType.HEARTRATE_ZONES,
            SeriesType.SPEED_ZONES, SeriesType.TEMPO_ZONES, SeriesType.TEMPO_500_ZONES,
            SeriesType.FORCE_ZONES].includes(curr), false);

    const [isChartDialogOpen, setIsChartDialogOpen] = useState(false);
    const [chartDialogSeriesType, setChartDialogSeriesType] = useState<SeriesType>();

    const openChartDialog = useCallback((x: SeriesType) => {
        setChartDialogSeriesType(x);
        setIsChartDialogOpen(true);
    }, []);

    const dismissChartDialog = useCallback(() => {
        setIsChartDialogOpen(false);
    }, []);

    return (
        <div>
            {panelNumber && <h5>{t("panel title format", {number: panelNumber})}</h5>}
            {charts.includes(SeriesType.GPS) &&
                <TrainingMap
                    coords={trainingCommon.gpsData ?? []}
                    selectStart={sliceStartIndex * (isTracker ? 10 : 1)}
                    selectEnd={sliceEndIndex * (isTracker ? 10 : 1)}
                    mapRef={mapRef}
                    mapsRef={mapsRef}/>
            }
            {trainingCommon.trainings.length > 1 && <div className={styles.athleteSelector}>
                {trainingCommon.trainings.map((x, i) => {
                        const isSelected = selectedTrainingIndexes.includes(i);
                        return <div key={i}
                                    className={`${styles.athlete} ${isSelected ? styles.selected : ""}`}
                                    onClick={() => toggleSelectedTraining(i)}>
                            <div className={styles.athleteInner}>
                                {x.user && <>
                                    <img className={styles.profilePicture} alt="" src={x.user.profilePictureUrl}/>
                                    <span
                                        className={styles.athleteName}>{`${x.user.firstName[0]}${x.user.lastName[0] || ""}`}</span>
                                </>}
                                {!x.user && <>
                                    <Icon className={styles.profilePicture} color={"#000"} path={mdiAccount}/>
                                    {t("guest")}
                                </>}
                            </div>

                            <div
                                className={`${styles.color} ${styles.left}`}
                                style={{
                                    backgroundColor: isSelected ?
                                        forceSeriesColors[(i * (isTwoPaddle(trainingCommon.sport) ? 2 : 1))
                                        % forceSeriesColors.length] : "transparent"
                                }}>
                            </div>
                            {isTwoPaddle(trainingCommon.sport) && <div
                                className={`${styles.color} ${styles.right}`}
                                style={{
                                    backgroundColor: isSelected ?
                                        forceSeriesColors[(i * 2 + 1) % forceSeriesColors.length] : "transparent"
                                }}>
                            </div>}
                        </div>;
                    }
                )}
            </div>}
            <div className={styles.panelOptions}>
                <div className={styles.glanceContainer}>
                    {hasSomeForces &&
                        <GlanceIndicatorSmall
                            icon={getStatIcon(SmallStatCategory.Force)}
                            text={t("avg pulling force")}
                            value={`${sliceForceAvg.toFixed(0)} N`}
                            smallValue={`${t("max")} ${sliceForceMax.toFixed(0)} N`}
                        />
                    }
                    {!hasSomeForces &&
                        <GlanceIndicatorSmall
                            icon={getStatIcon(SmallStatCategory.Force)}
                            text={t("avg pulling force")}
                            value=""
                            unavailable={true}
                        />
                    }
                    {strokeSeries &&
                        <GlanceIndicatorSmall
                            icon={"/icons/ic_strokes.png"}
                            text={t("avg stroke rate")}
                            value={`${sliceStrokeAvg.toFixed(1)} / min`}
                            smallValue={`${t("max")} ${sliceStrokeMax.toFixed(1)} / min`}
                        />
                    }
                    {!strokeSeries &&
                        <GlanceIndicatorSmall
                            icon={"/icons/ic_strokes.png"}
                            text={t("avg stroke rate")}
                            value=""
                            unavailable={true}
                        />
                    }
                    <GlanceIndicatorSmall
                        icon={getStatIcon(SmallStatCategory.Distance)}
                        text={t("distance")}
                        value={`${sliceDistance.toFixed(1)} km`}
                    />
                    <GlanceIndicatorSmall
                        icon={getStatIcon(SmallStatCategory.Duration)}
                        text={t("duration")}
                        value={`${Formatter.secToTimeString((Math.ceil(selection[1] / 1000) - Math.floor(selection[0] / 1000)))}`}
                    />
                    {isRowing(trainingCommon.sport) && <>
                        {tempo500Series &&
                            <GlanceIndicatorSmall
                                icon={getStatIcon(SmallStatCategory.Speed)}
                                text={t("avg tempo split")}
                                value={`${Highcharts.dateFormat("%M:%S", sliceTempo500Avg * 60 * 1000)} /500m`}
                                smallValue={`${t("max")} ${Highcharts.dateFormat("%M:%S", sliceTempo500Max * 60 * 1000)} /500m`}
                            />
                        }
                        {!tempo500Series &&
                            <GlanceIndicatorSmall
                                icon={getStatIcon(SmallStatCategory.Speed)}
                                text={t("avg tempo split")}
                                value=""
                                unavailable={true}
                            />
                        }
                    </>}
                    {!isRowing(trainingCommon.sport) && <>
                        {speedSeries &&
                            <GlanceIndicatorSmall
                                icon={getStatIcon(SmallStatCategory.Speed)}
                                text={t("avg speed")}
                                value={`${sliceSpeedAvg.toFixed(1)} km/h`}
                                smallValue={`${t("max")} ${sliceSpeedMax.toFixed(1)} km/h`}
                            />
                        }
                        {!speedSeries &&
                            <GlanceIndicatorSmall
                                icon={getStatIcon(SmallStatCategory.Speed)}
                                text={t("avg speed")}
                                value=""
                                unavailable={true}
                            />
                        }
                    </>}
                    {sliceHeartRateAvg !== 0 &&
                        <GlanceIndicatorSmall
                            icon={getStatIcon(SmallStatCategory.HeartRate)}
                            text={t("avg heart rate")}
                            value={`${sliceHeartRateAvg.toFixed(1)} bpm`}
                            smallValue={`${t("max")} ${sliceHeartRateMax.toFixed(1)} bpm`}
                        />
                    }
                    {sliceHeartRateAvg === 0 &&
                        <GlanceIndicatorSmall
                            icon={getStatIcon(SmallStatCategory.HeartRate)}
                            text={t("avg heart rate")}
                            value=""
                            unavailable={true}
                        />
                    }
                </div>
                <ChartSelector charts={charts} enabledCharts={enabledCharts}
                               syncDetailedForceView={syncDetailedForceView}
                               onDone={(seriesType, syncDetailedForceView) => {
                                   setCharts(seriesType);
                                   setSyncDetailedForceView(syncDetailedForceView);
                               }}/>
                {!closable &&
                    <div className={styles.closeContainer} onClick={onClose}>
                        <Icon path={mdiClose} size={1.2}/>
                    </div>
                }
            </div>
            <SizedBox height={16}/>
            <StandardGraphs
                heartRateSeries={heartRateSeries}
                heartRateMax={heartRateMax}
                speedSeries={speedSeries}
                speedMax={speedMax}
                tempoSeries={tempoSeries}
                tempoMax={speedToTempo(speedMax)}
                tempo500Series={tempo500Series}
                tempo500Max={speedToTempo500(speedMax)}
                strokeSeries={strokeSeries}
                strokeMax={strokeRateMax}
                distancePerStrokeSeries={distancePerStrokeSeries}
                distancePerStrokeMax={distancePerStrokeMax}
                forceLeftSeries={forceLeftSeries}
                forceRightSeries={forceRightSeries}
                podBatteryLeftSeries={podBatteryLeftSeries}
                podBatteryRightSeries={podBatteryRightSeries}
                forceMax={pullingForceMax}
                distanceSeries={distanceSeries}
                hrZonesData={selectedHrZoneData}
                speedZonesData={speedZonesData}
                tempoZonesData={tempoZonesData}
                tempo500ZonesData={tempo500ZonesData}
                forceZonesData={forceZonesData}
                band={zoom100 ? null : [Math.floor(selection[0] / 1000) * 1000,
                    Math.ceil(selection[1] / 1000) * 1000]}
                detailedBand={zoom100Detailed || syncDetailedForceView ?
                    null : [detailedSliceStart, detailedSliceEnd]}
                charts={charts}
                cursor={focusedTimeRef}
                sliceStart={sliceStart}
                sliceEnd={sliceEnd}
                max={sliceMax}
                hrZones={singleHrZones}
                sport={trainingCommon.sport}
                hasZones={hasZones}
                isTracker={isTracker}
                onSelectionChange={(selection) => {
                    setSelection(selection)
                    setSliceStart(selection[0]);
                    setSliceEnd(selection[1]);
                    setDetailedSliceStart(selection[0]);
                    setDetailedSliceEnd(selection[1]);
                }}
                onZoomChange={(selection) => {
                    setSliceStart(selection[0]);
                    setSliceEnd(selection[1]);
                    setDetailedSliceStart(selection[0]);
                    setDetailedSliceEnd(selection[1]);
                }}
                onCursorChange={(cursor) => {
                    focusedTimeRef.current = cursor;
                }}
                onFullscreenClick={openChartDialog}
            />
            {showDetailedChart &&
                <DetailedPaddleForceGraph
                    leftForces={detailedLeftForces}
                    rightForces={detailedRightForces}
                    zoomStart={syncDetailedForceView ? sliceStart : detailedSliceStart}
                    zoomEnd={syncDetailedForceView ? sliceEnd : detailedSliceEnd}
                    maxX={sliceMax}
                    distanceSeries={distanceSeries}
                    bandStart={zoom100 ? null :
                        (Math.floor(sliceStart / 1000) * 1000)}
                    bandEnd={zoom100 ? null :
                        (Math.ceil(sliceEnd / 1000) * 1000)}
                    sport={trainingCommon.sport}
                    hasZones={hasZones}
                    cursor={focusedTimeRef}
                    isTracker={isTracker}
                    onZoomChange={(minVal, maxVal) => {
                        if (sliceStart === minVal && sliceEnd === maxVal) return;
                        if (syncDetailedForceView) {
                            setSelection([minVal, maxVal]);
                            setSliceStart(minVal);
                            setSliceEnd(maxVal);
                        }
                        setDetailedSliceStart(minVal);
                        setDetailedSliceEnd(maxVal);
                    }}
                    onCursorChange={(cursor) => {
                        focusedTimeRef.current = cursor;
                    }}
                    onFullscreenClick={() => openChartDialog(SeriesType.DETAILS)}
                />
            }
            {trainingCommonFile?.imuSeries && <ImuGraph
                imuSeries={trainingCommonFile?.imuSeries}
                zoomStart={syncDetailedForceView ? sliceStart : detailedSliceStart}
                zoomEnd={syncDetailedForceView ? sliceEnd : detailedSliceEnd}
                maxX={sliceMax}
                distanceSeries={distanceSeries}
                bandStart={zoom100 ? null :
                    (Math.floor(sliceStart / 1000) * 1000)}
                bandEnd={zoom100 ? null :
                    (Math.ceil(sliceEnd / 1000) * 1000)}
                hasZones={hasZones}
                cursor={focusedTimeRef}
                isTracker={isTracker}
                onZoomChange={(minVal, maxVal) => {
                    if (sliceStart === minVal && sliceEnd === maxVal) return;
                    if (syncDetailedForceView) {
                        setSelection([minVal, maxVal]);
                        setSliceStart(minVal);
                        setSliceEnd(maxVal);
                    }
                    setDetailedSliceStart(minVal);
                    setDetailedSliceEnd(maxVal);
                }}
                onCursorChange={(cursor) => {
                    focusedTimeRef.current = cursor;
                }}
                onFullscreenClick={() => openChartDialog(SeriesType.IMUS)}
            />}
            {/*Only show if the other stepper is not visible*/}
            {charts.find(x => SeriesType.DETAILS === x) &&
                hasSomeDetailedForces &&
                !mobileOrTablet &&
                !(charts.find(x => [SeriesType.HEARTRATE,
                    SeriesType.SPEEDS, SeriesType.TEMPOS, SeriesType.TEMPO_500S, SeriesType.PULLING_FORCE,
                    SeriesType.STROKES].includes(x))) &&
                <ChartStepper
                    style={{marginLeft: 80, marginBottom: 20}}
                    min={0}
                    max={sliceMax}
                    from={detailedSliceStart}
                    to={detailedSliceEnd}
                    onChange={(from, to) => {
                        if (from < to) {
                            if (syncDetailedForceView) {
                                setSliceStart(from);
                                setSliceEnd(to);
                            }
                            setDetailedSliceStart(from);
                            setDetailedSliceEnd(to);
                        }
                    }}
                />}
            <Modal isOpen={isChartDialogOpen} fullscreen>
                <ChartDialog
                    onDismiss={dismissChartDialog}
                    heartRateSeries={heartRateSeries}
                    speedSeries={speedSeries}
                    tempoSeries={tempoSeries}
                    tempo500Series={tempo500Series}
                    strokeSeries={strokeSeries}
                    distancePerStrokeSeries={distancePerStrokeSeries}
                    forceLeftSeries={forceLeftSeries}
                    forceRightSeries={forceRightSeries}
                    detailedLeftForces={detailedLeftForces}
                    detailedRightForces={detailedRightForces}
                    distanceSeries={distanceSeries}
                    imuSeries={trainingCommonFile?.imuSeries}
                    maxX={sliceMax}
                    zoomStart={syncDetailedForceView ? sliceStart : detailedSliceStart}
                    zoomEnd={syncDetailedForceView ? sliceEnd : detailedSliceEnd}
                    isTracker={isTracker}
                    athletes={trainingCommon.trainings.map(x => x.user)}
                    defaultSeriesType={chartDialogSeriesType!}
                    sport={trainingCommon.sport}
                    onZoomChange={(minVal, maxVal) => {
                        if (sliceStart === minVal && sliceEnd === maxVal) return;
                        if (syncDetailedForceView) {
                            setSelection([minVal, maxVal]);
                            setSliceStart(minVal);
                            setSliceEnd(maxVal);
                        }
                        setDetailedSliceStart(minVal);
                        setDetailedSliceEnd(maxVal);
                    }}/>
            </Modal>
        </div>
    );
};
