import {mdiFullscreen, mdiFullscreenExit} from '@mdi/js';
import Icon from '@mdi/react';
import {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {SizedBox} from '../../components/base/SizedBox/SizedBox';
import DashboardLayout from '../../components/layouts/DashboardLayout/DashboardLayout';
import {AnalyticsHeader} from "../../components/paddlemate/AnalyticsHeader/AnalyticsHeader";
import {AnalyticsPanel, ISlice} from "../../components/paddlemate/AnalyticsPanel/AnalyticsPanel";
import ResponsiveContext from '../../contexts/ResponsiveContext';
import UserContext from '../../contexts/UserContext';
import {Api} from '../../utils/api';
import styles from './AnalyticsPage.module.css';
import {Spinner} from "../../components/base/Spinner/Spinner";
import {Col, Form, FormGroup, Input, Label, Row} from "reactstrap";
import {Link, useParams} from "react-router-dom";
import {ITrainingCommon} from "../../typings/ITrainingCommon";
import {max} from "../../utils/arrayUtils";
import {ITrainingCommonFile} from "../../typings/ITrainingCommonFile";
import saveFile from "../../utils/fileSaver";
import sanitize from "sanitize-filename";
import moment from 'moment';

const Metadata = ({training}: { training: ITrainingCommon }) => {
    return <Form>
        <Row>
            <Col md={2}><FormGroup>
                <Label>Common id</Label>
                <Input value={training.id} readOnly/>
            </FormGroup></Col>
            <Col md={2}><FormGroup>
                <Label>Ids</Label>
                <Input value={training.trainings.map(x => x.id.toString()).join(", ")} readOnly/>
            </FormGroup></Col>
            <Col md={3}><FormGroup>
                <Label>Created by</Label>
                <div>
                    <Link
                        to={`/users/${training.createdBy.id}`}>{`${training.createdBy.name} (${training.createdBy.id})`}</Link>
                </div>
            </FormGroup></Col>
            {training.recorderDevice && <Row>
                <Col md={2} style={{display: "flex", alignItems: "center"}}>Recorder device</Col>
                <Col md={1}><FormGroup>
                    <Label>Type</Label>
                    <Input value={training.recorderDevice.type || ""} readOnly/>
                </FormGroup></Col>
                <Col md={1}><FormGroup>
                    <Label>HW</Label>
                    <Input value={training.recorderDevice.hw || ""} readOnly/>
                </FormGroup></Col>
                <Col md={1}><FormGroup>
                    <Label>SW</Label>
                    <Input value={training.recorderDevice.sw || ""} readOnly/>
                </FormGroup></Col>
            </Row>}
            <Row className={"mb-3"}>
                <Col md={2} style={{display: "flex", alignItems: "center"}}>Athletes</Col>
                <Col md={10} className={"d-flex align-items-center"} style={{minHeight: 38, gap: "1em"}}>
                    {training.trainings.map(x => <Link key={x.id}
                                                       to={x.user ? `/users/${x.user.id}` : ''}>{`${x.user?.name ?? "guest athlete"}`}{x.user && `(${x.user?.id})`}</Link>)}
                </Col>
            </Row>
            {training.trainings.map((tct, ti) => tct.devices.map((x, i) =>
                <Row key={`${tct.id}-${i}`}>
                    <Col md={2} style={{display: "flex", alignItems: "center"}}>Athlete {ti + 1}. Device {i + 1}.</Col>
                    <Col md={1}><FormGroup>
                        <Label>Type</Label>
                        <Input value={x.type || ""} readOnly/>
                    </FormGroup></Col>
                    <Col md={1}><FormGroup>
                        <Label>HW</Label>
                        <Input value={x.hw || ""} readOnly/>
                    </FormGroup></Col>
                    <Col md={2}><FormGroup>
                        <Label>BT</Label>
                        <Input value={x.btName || ""} readOnly/>
                    </FormGroup></Col>
                    <Col md={1}><FormGroup>
                        <Label>SW</Label>
                        <Input value={x.sw || ""} readOnly/>
                    </FormGroup></Col>
                    <Col md={1}><FormGroup>
                        <Label>Base hw</Label>
                        <Input value={x.baseHw || ""} readOnly/>
                    </FormGroup></Col>
                    <Col md={1}><FormGroup>
                        <Label>Base SN</Label>
                        <Input value={x.baseSn || ""} readOnly/>
                    </FormGroup></Col>
                    <Col md={1}><FormGroup>
                        <Label>Base calib.</Label>
                        <Input value={x.baseCalibration || ""} readOnly/>
                    </FormGroup></Col>
                </Row>))}
        </Row>
    </Form>
}

export const AnalyticsPage = () => {
    const {t} = useTranslation();
    const {fullScreen, setFullScreen} = useContext(ResponsiveContext);
    const [trainingData, setTrainingData] = useState<ITrainingCommon | null>(null);
    const [trainingFile, setTrainingFile] = useState<ITrainingCommonFile | null>(null);
    const {user, isCoach} = useContext(UserContext);
    const [exportSlice, setExportSlice] = useState<ISlice | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [trainingFileLoading, setTrainingFileLoading] = useState(false);
    const {trainingId: trainingIdParam} = useParams();
    const trainingId = parseInt(trainingIdParam!);
    const [reload, setReload] = useState(0);

    useEffect(() => {
        let cancelled = false;
        (async () => {
            setLoading(true);
            const res = await Api.getTrainingCommon(trainingId);
            if (cancelled) return;
            setTrainingData(res);
        })().catch(e => {
            console.error(e);
        }).finally(() => {
            if (cancelled) return;
            setLoading(false);
        });
        (async () => {
            setTrainingFileLoading(true);
            const file = await Api.getTrainingCommonFile(trainingId);
            if (cancelled) return;
            setTrainingFile(file);
        })().catch(e => {
            console.error(e);
        }).finally(() => {
            if (cancelled) return;
            setTrainingFileLoading(false);
        });

        return () => {
            cancelled = true;
        };
    }, [trainingId, user, t, isCoach, reload]);

    useEffect(() => {
        return () => setFullScreen(false);
    }, [setFullScreen]);

    const heartRateMax = useMemo(() => trainingData && max(trainingData.trainings
        .filter(x => x.heartRateBpmSeries)
        .map(x => x.maxHeartRateBpm!)), [trainingData]);
    const speedMax = useMemo(() => trainingData?.speedData &&
        trainingData.maxSpeedKph, [trainingData]);
    const strokeRateMax = useMemo(() => trainingData?.strokeData &&
        trainingData.maxStrokeRateSpm, [trainingData]);
    const distancePerStrokeMax = useMemo(() => {
        return trainingData?.distancePerStrokeData &&
            max(trainingData.distancePerStrokeData);
    }, [trainingData]);
    const pullingForceMax = useMemo(() => trainingData && max(
        (trainingData.trainings.map(x =>
            [x.leftPaddlingForceNSeries, x.rightPaddlingForceNSeries]).flat()
            .filter(x => x) as unknown as number[]).flat()), [trainingData]);

    const handleSelectionChange = useCallback(
        (slice: ISlice | null) => {
            setExportSlice(slice);
        },
        [],
    );

    if (trainingData === null)
        return <DashboardLayout>
            <SizedBox height={8}/>
            {loading && <div style={{display: "flex", alignItems: "center", justifyContent: "center", marginTop: 16}}>
                <Spinner/>
            </div>}
            {!loading && <div style={{display: "flex", alignItems: "center", justifyContent: "center", marginTop: 16}}>
                {t("loading failed")}
            </div>}
        </DashboardLayout>;

    const name = trainingData.trainings.map(t => t.user?.name ?? "guest athlete").join("_") + "_" +
        trainingData.name + "_" +
        (moment(trainingData.startAt).format("YYYY-MM-DD_HH-mm")) + trainingId;
    const safeName = sanitize(name).replace(/[\s]/g, "_");

    return (
        <DashboardLayout fullScreen={fullScreen}>
            <SizedBox height={8}/>
            <AnalyticsHeader
                onChanged={() => setReload(x => x + 1)}
                trainingData={trainingData}
            />
            {trainingFileLoading && <Spinner size={20} cssOverride={{marginTop: 10}}/>}
            {!trainingFileLoading && <>
                {trainingFile &&
                    <button type="button" className="btn btn-link p-0"
                            onClick={() => saveFile(trainingFile, `${safeName}.json`, "application/json")}>
                        Download raw
                    </button>}
                {!trainingFile && <div>No raw</div>}
            </>}
            <Metadata training={trainingData}/>
            <AnalyticsPanel
                trainingCommon={trainingData}
                trainingCommonFile={trainingFile}
                onSelectionChange={handleSelectionChange}
                closable={true}
                onSelectedTrainingsChanged={() => {
                }}
                heartRateMax={heartRateMax!}
                speedMax={speedMax!}
                strokeRateMax={strokeRateMax!}
                distancePerStrokeMax={distancePerStrokeMax!}
                pullingForceMax={pullingForceMax!}
            />
            <SizedBox height={20}/>
            <div className={`${styles.fullScreenButton} ${!fullScreen ? styles.fullScreenButtonOffset : ""}`}
                 onClick={() => setFullScreen(!fullScreen)}>
                <Icon
                    size={1.5}
                    path={!fullScreen ? mdiFullscreen : mdiFullscreenExit}
                />
            </div>
        </DashboardLayout>
    );
}
