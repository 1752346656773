import {MutableRefObject, useCallback, useEffect, useMemo, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import moment from "moment/moment";
import {max, min} from "../../../../utils/arrayUtils";
import {forceSeriesColors, zoneWidth} from "../StandardGraphs/StandardGraphs";
import {isTwoPaddle, TrainingSport} from "../../../../typings/TrainingSport";
import {UPlot} from "../../../uplot/UPlot";
import uPlot, {Axis, Cursor} from "uplot";
import {useResizeDetector} from "react-resize-detector";
import {autoUpdate, Boundary, flip, offset, useFloating} from "@floating-ui/react";
import {TooltipChangedListener, TooltipPlugin} from "../../../uplot/TooltipPlugin";
import {TouchZoomPlugin} from "../../../uplot/TouchZoomPlugin";
import {WheelPanPlugin} from "../../../uplot/WheelPanPlugin";
import styles from "./DetailedPaddleForceGraph.module.css";
import {Button} from "reactstrap";
import Icon from "@mdi/react";
import {mdiFullscreen} from "@mdi/js";


interface IDetailedPaddleForceGraph {
    leftForces: (number[] | null)[];
    rightForces: (number[] | null)[];
    distanceSeries: number[][] | null;
    bandStart: number | null,
    bandEnd: number | null,
    zoomStart: number,
    zoomEnd: number,
    maxX: number;
    sport: TrainingSport;
    hasZones: boolean;
    cursor: MutableRefObject<number>;
    isTracker: boolean;
    onZoomChange: (min: number, max: number) => void;
    onCursorChange: (cursor: number) => void;
    onFullscreenClick: () => void;
}

const detailedChartHeight = 300;
const chartPaddingTop = 10;

export const DetailedPaddleForceGraph = ({
                                             leftForces,
                                             rightForces,
                                             distanceSeries,
                                             bandStart,
                                             bandEnd,
                                             zoomStart,
                                             zoomEnd,
                                             maxX,
                                             sport,
                                             hasZones,
                                             cursor,
                                             isTracker,
                                             onZoomChange,
                                             onCursorChange,
                                             onFullscreenClick
                                         }: IDetailedPaddleForceGraph) => {
    const {t} = useTranslation();
    const uPlot = useRef<uPlot>();

    const series = useMemo(() =>
        leftForces.map((_, i) => {
            return [
                {
                    data: leftForces[i],
                    color: forceSeriesColors[(i * (isTwoPaddle(sport) ? 2 : 1)) % forceSeriesColors.length]
                },
                {
                    data: rightForces[i],
                    color: forceSeriesColors[(i * (isTwoPaddle(sport) ? 2 : 1) + 1) % forceSeriesColors.length]
                }
            ];
        }).flat().filter(x => x.data), [leftForces, rightForces, sport]);

    const allForces = useMemo(() =>
            [...leftForces, ...rightForces].filter(x => x != null) as number[][],
        [leftForces, rightForces]);
    const totalMinMax = useMemo(() => {
            const minMaxByForceArray = allForces.map(forces => {
                return forces.reduce((res, force) => {
                    if (res.min > force)
                        res.min = force;
                    else if (res.max < force)
                        res.max = force;
                    return res;
                }, {min: Infinity, max: -Infinity});
            });
            return {min: min(minMaxByForceArray.map(x => x.min)), max: max(minMaxByForceArray.map(x => x.max))};
        }
        , [allForces]);

    const [isOpen, setIsOpen] = useState(false);
    const [uPlotBoundary, setUPlotBoundary] = useState<Boundary>();

    const {refs, floatingStyles} = useFloating({
        placement: "right-end",
        open: isOpen,
        whileElementsMounted: autoUpdate,
        middleware: [offset(30), flip({boundary: uPlotBoundary})],
    });

    const [tooltipValue, setTooltipValue]
        = useState<{ x: number, ys: (number | null | undefined)[] } | null>(null);
    const handleTooltipChanged = useCallback<TooltipChangedListener>(
        (x, ys, px, py) => {
            refs.setReference({
                getBoundingClientRect: () =>
                    ({
                        x: px,
                        y: py + 20,
                        top: py + 20,
                        left: px,
                        bottom: py + 20,
                        right: px,
                        width: 0,
                        height: 0,
                    }),
            });
            setTooltipValue({x, ys});
        }, [refs]);
    const handleTooltipVisibleChanged = useCallback(
        (value: boolean) => {
            setIsOpen(value);
        }, []);
    const {width, ref: sizeDetectorRef} = useResizeDetector();

    const data = useMemo<[
        xValues: number[],
        ...yValues: number[][],
    ]>(() => [
        [...Array(series[0].data!!.length).keys()].map((x, i) => i * 10), // 10 hz is the frequency
        ...series.map(x => x.data!!)
    ], [series]);
    const zoomingWithTouch = useRef<boolean>(false);

    const callOnSelectionChange = useCallback(() => {
        if (!uPlot.current) return;
        const u = uPlot.current;
        if (u.scales.x.min != null && u.scales.x.max != null) {
            let min = u.scales.x.min;
            let max = Math.min(u.scales.x.max, maxX);
            if (min === 0 && max !== maxX)
                min = 0;
            if (max === maxX && min !== 0)
                max = maxX;
            onZoomChange(min, max);
        }
    }, [onZoomChange, maxX]);

    const handleSetScale = useCallback((u: uPlot, scaleKey: string) => {
        if (!zoomingWithTouch.current) // we only call callOnSelectionChange after the touch zooming is over, to prevent reinitializing the chart
            callOnSelectionChange();
    }, [callOnSelectionChange]);

    const handleDrawBands = useCallback((u: uPlot) => { // draw bands with custom renderer
        if (bandStart == null || bandEnd == null) return;
        const min = u.scales.x.min ?? 0;
        const max = u.scales.x.max ?? 0;
        // do not draw out of range
        if ((bandStart < min && bandEnd < min) || (bandStart > max && bandEnd > max))
            return;
        let {ctx} = u;
        const height = u.bbox.height - (u.axes[0].ticks?.size ?? 0);

        ctx.save();

        ctx.fillStyle = "rgba(140,140,140,0.07)";

        const clampedBandStart = (bandStart < min && bandEnd > min) ? min : bandStart;
        const clampedBandEnd = (bandEnd > max && bandEnd < max) ? max : bandEnd;
        const startPx = Math.round(u.valToPos(clampedBandStart, "x", true));
        const endPx = Math.round(u.valToPos(clampedBandEnd, "x", true));
        ctx.fillRect(startPx, chartPaddingTop, endPx - startPx, chartPaddingTop + height);

        ctx.restore();
    }, [bandEnd, bandStart]);

    const xSpaceFn = useCallback<(self: uPlot, axisIdx: number, scaleMin: number, scaleMax: number, plotDim: number) => number>
    ((self, axisIdx, scaleMin, scaleMax, dim) => {
        const range = scaleMax - scaleMin;
        return range < 4000 ? 70 : 50;
    }, []);

    const xValuesFn = useCallback<Axis.DynamicValues>
    ((self, ticks, space) => ticks.map(tick => {
        const index = Math.floor(tick / (isTracker ? 100 : 1000));
        const distance = (distanceSeries?.[index]?.[1] ?? 0).toFixed(1);
        const range = ticks[ticks.length - 1] - ticks[0];
        if (range < 4000)
            return String(moment(tick).utc().format("H:mm:ss.SSS")) + `\n${distance} km`;
        else
            return String(moment(tick).utc().format("H:mm:ss")) + `\n${distance} km`;
    }), [distanceSeries, isTracker]);

    const handleCursorMove = useCallback<Cursor.MousePosRefiner>((u, left, top) => {
        const pos = u.valToPos(data[0][u.posToIdx(left)], "x");
        return [pos, top];
    }, [data]);

    const handleZoomingChanged = useCallback((value: boolean) => {
        zoomingWithTouch.current = value;
        if (!value)
            callOnSelectionChange();
    }, [callOnSelectionChange]);

    const handleCursorChanged = useCallback((u: uPlot) => {
        if (u.cursor.left !== undefined)
            onCursorChange(Math.floor(u.posToVal(u.cursor.left, "x")))
    }, [onCursorChange]);

    useEffect(() => {
        const t = window.setInterval(() => {
            if (!uPlot.current) return;
            uPlot.current.setCursor({left: uPlot.current.valToPos(cursor.current, "x"), top: 0});
        }, 200);
        return () => clearInterval(t);
    }, [cursor]);

    const options = useMemo<uPlot.Options>(() => {
        return ({
            width: width ?? 1224,
            height: detailedChartHeight,
            padding: [chartPaddingTop, 10, 0, 0],
            hooks: {
                setScale: [handleSetScale],
                drawAxes: [handleDrawBands],
                setCursor: [handleCursorChanged]
            },
            series: [
                {
                    label: "X"
                },
                ...series.map(s => ({
                    stroke: s.color
                }))
            ],
            scales: {
                "x": {
                    time: false,
                    min: zoomStart,
                    max: zoomEnd
                },
                "y": {
                    range: [Math.max(-50, totalMinMax.min), totalMinMax.max + 5],
                }
            },
            axes: [
                {
                    grid: {show: false},
                    ticks: {
                        show: true,
                        stroke: "#000000",
                        width: 1
                    },
                    space: xSpaceFn,
                    values: xValuesFn,
                },
                {
                    ticks: {
                        show: false,
                    },
                    grid: {width: 1},
                    stroke: "rgb(102, 102, 102)",
                    label: t("detailed force"),
                    labelFont: "13px Helvetica, Arial, sans-serif",
                    labelSize: 30
                },
            ],
            legend: {
                show: false
            },
            cursor: {
                show: true,
                y: false,
                move: handleCursorMove
            },
            plugins: [
                new TouchZoomPlugin(handleZoomingChanged, maxX),
                new TooltipPlugin(handleTooltipChanged, handleTooltipVisibleChanged),
                new WheelPanPlugin(maxX)
            ]
        });
    }, [width, handleSetScale, handleDrawBands, handleCursorChanged, series, zoomStart, zoomEnd,
        totalMinMax.min, totalMinMax.max, xSpaceFn, xValuesFn, t, handleCursorMove,
        handleZoomingChanged, maxX, handleTooltipChanged, handleTooltipVisibleChanged]);

    const handleRefChanged = useCallback((e: any) => {
        sizeDetectorRef(e);
        if (e)
            setUPlotBoundary(e);
    }, [sizeDetectorRef]);

    const tooltipDistance = useMemo(() => {
        const index = Math.floor((tooltipValue?.x ?? 0) / 1000);
        return (distanceSeries?.[index]?.[1] ?? 0).toFixed(1);
    }, [distanceSeries, tooltipValue]);

    if (series.length === 0)
        return null;
    return <>
        <div
            className={styles.container}
        >
            <div style={{flex: 1, display: "flex", flexDirection: "column", minWidth: 0, position: "relative"}}>
                <div ref={handleRefChanged}>
                    <UPlot options={options}
                           data={data}
                           uPlotRef={uPlot}
                    />
                </div>
                <div className={styles.topRight}>
                    <div className={styles.buttons}>
                        <Button size="sm" outline={true} className={styles.fullscreenButton}
                                onClick={onFullscreenClick}>
                            <Icon path={mdiFullscreen}
                                  size={0.8}/>
                        </Button>
                    </div>
                </div>
            </div>
            {hasZones && <div className={styles.zonesContainer}>
                <div style={{width: zoneWidth, height: 1}}/>
            </div>}
        </div>
        <div
            ref={refs.setFloating}
            style={{
                ...floatingStyles,
                pointerEvents: "none",
                background: "white",
                boxShadow: "1px 1px 6px rgba(0, 0, 0, 0.6)",
                borderRadius: 2,
                padding: 8,
                fontFamily: "Helvetica, Arial, sans-serif",
                display: "flex",
                alignItems: "center",
                fontSize: "0.8em",
                zIndex: 10
            }}
        >
            <div>
                {String(moment(tooltipValue?.x ?? 0).utc().format("H:mm:ss.SSS"))}
                {distanceSeries && distanceSeries.length > 0 && <>
                    <br/>
                    {tooltipDistance} km
                </>}
            </div>
            <div style={{marginLeft: 10}}>
                {tooltipValue?.ys?.map((x, i) =>
                    <div key={i} style={{color: series[i].color, fontWeight: 700}}>{x?.toFixed(0) ?? 0} N</div>)
                }
            </div>
        </div>
    </>;
};
