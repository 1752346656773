import {IAthlete} from "../typings/IAthlete";
import {ICoach} from "../typings/ICoach";
import {ICoachingRequest} from "../typings/ICoachingRequest";
import {IToken} from "../typings/IToken";
import {IUser} from "../typings/IUser";
import {IUserInfo} from "../typings/IUserInfo";
import {TimeGrouping} from "../typings/TimeGrouping";
import {apiConfig} from "./apiConfig";
import {ApiEC} from "./ApiEC";
import axiosApi, {get} from "./axios";
import {Transform} from "./transfrom";
import {Moment} from "moment";
import {IAdminTrainingStatisticsData} from "../typings/IAdminTrainingStatisticsData";
import {IAdminUserStatisticsData} from "../typings/IAdminUserStatisticsData";
import {IApiTrainingCommonBase} from "../typings/IApiTrainingCommonBase";

interface IAuth {
    token: IToken | null;
}

const auth: IAuth = {
    token: null,
};

function setAuthToken(token: IToken | null) {
    auth.token = token;
}

function getAuthHeader() {
    return {
        headers: {
            Authorization: `Bearer ${auth?.token?.access_token}`,
        },
    };
}

async function resetPassword(password: string, encodedHash: string) {
    const res = await axiosApi.post("/api/Users/resetPassword", {
        password,
        encodedHash,
    });
    return res.status;
}

async function resetPasswordRequest(email: string) {
    const res = await axiosApi.post("/api/Users/requestPasswordReset", {
        email
    });
    return res.status;
}

async function getUser(userId: string) {
    const res = await axiosApi.get(`/api/Users/${userId}`, getAuthHeader());
    if (!res.data || !res.data.id) {
        throw new Error(ApiEC.UserGetFailed);
    }
    return res.data as IUser;
}

const userCache = new Map<string, IUser>();

async function getUserCached(id: string) {
    // Check if the data is already cached
    if (userCache.has(id)) {
        return userCache.get(id)!;
    }
    const userData = await Api.getUser(id);
    userCache.set(id, userData);
    return userData;
}

const fetchAthletes = async (coachId: number) => {
    const res = await axiosApi.get(`/api/coaches/${coachId}/athletes`, getAuthHeader());
    if (!res.data || (res.data.length === undefined)) {
        throw new Error('fetchathletes-failed');
    }
    return res.data as IAthlete[];
}

const getUsers = async (query?: string, limit = 100, offset: number = 0) => {
    const res = await axiosApi.get(`/api/users/`, {
        ...getAuthHeader(),
        params: {
            ...(query != null && {query}),
            ...(offset != null && {offset}),
            ...(limit != null && {limit: limit + 1}),
        }
    });
    if (!res.data || (res.data.length === undefined)) {
        throw new Error('getUsers-failed');
    }
    const hasMore = res.data.length > limit;
    if (hasMore) res.data.splice(res.data.length - 1, 1);
    return {
        data: res.data,
        hasMore,
    };
}

const deleteAthlete = async (coachId: number, athleteId: number) => {
    const res = await axiosApi.delete(`/api/coaches/${coachId}/athletes/${athleteId}`, getAuthHeader());
    return res.status === 200;
}

const getStatistics = async (timeGrouping: TimeGrouping, from: Date, to: Date, userId?: number, coachId?: number) => {
    const res = await get(
        !!userId ? `/api/statistics/${userId}` : '/api/statistics',
        getAuthHeader(),
        {
            groupBy: timeGrouping,
            from: from.toISOString(),
            to: to.toISOString(),
            ...(!!coachId && ({coachId}))
        }
    );
    if (!res.data) {
        throw new Error('getstatistics-failed');
    }
    const d = res.data;
    return Transform.statisticsResponse(d, timeGrouping);
}

const getAdminStatisticsTraining = async (timeGrouping: TimeGrouping, from: Date, to: Date): Promise<IAdminTrainingStatisticsData> => {
    const res = await axiosApi.get(`/api/adminstatistics/trainings`, {
            ...getAuthHeader(),
            params: {
                groupBy: timeGrouping,
                from: from.toISOString(),
                to: to.toISOString(),
            }
        }
    );
    if (!res.data) {
        throw new Error('getAdminStatisticsTraining-failed');
    }
    return res.data;
}

const getAdminStatisticsUser = async (): Promise<IAdminUserStatisticsData> => {
    const res = await axiosApi.get(`/api/adminstatistics/users`, {
            ...getAuthHeader()
        }
    );
    if (!res.data) {
        throw new Error('getAdminStatisticsUser-failed');
    }
    return res.data;
}

const getTrainingCommon = async (trainingCommonId: number) => {
    const resDetail = await axiosApi.get(`/api/training-commons/${trainingCommonId}`);
    if (!resDetail.data) {
        throw new Error('training-common-failed');
    }
    const training = Transform.trainingCommonResponse(resDetail.data);
    return training;
}

const getTrainingCommonFile = async (trainingCommonId: number) => {
    const resDetail = await axiosApi.get(`/api/training-commons/${trainingCommonId}/file`);
    if (!resDetail.data) {
        throw new Error('training-common-file-failed');
    }
    const training = Transform.trainingCommonFileResponse(resDetail.data);
    return training;
}

const getTrainings = async (userId: number | null = null, limit = 100, offset: number = 0,
                            from: Moment | null = null, to: Moment | null = null, problemReported: boolean | null = null,
                            problemResolved: boolean | null = null, deviceQuery: string | null = null) => {
    const res = await axiosApi.get(`/api/training-commons`, {
        ...getAuthHeader(),
        params: {
            ...(userId && {userId}),
            limit: limit + 1,
            offset: offset,
            from: from?.toISOString(),
            to: to?.toISOString(),
            ...(problemReported !== null && {
                problemReported
            }),
            ...(problemResolved !== null && {
                problemResolved
            }),
            ...(deviceQuery !== null && {
                deviceQuery
            }),
        }
    });
    if (!res.data || (res.data.length === undefined)) {
        throw new Error('training-commons-failed');
    }
    const trainingCommons = (res.data as any[]).map((t) => ({
        ...t,
        startAt: new Date(t.startAt),
        endAt: new Date(t.endAt),
    } as IApiTrainingCommonBase));
    const hasMore = trainingCommons.length > limit;
    if (hasMore) trainingCommons.splice(trainingCommons.length - 1, 1);
    return {
        trainingCommons: trainingCommons,
        hasMore,
    };
}

const getMyCoaches = async (myUserId: number) => {
    const res = await axiosApi.get(`/api/athletes/${myUserId}/coaches`, getAuthHeader());
    if (!res.data) {
        throw new Error('getting current users coaches failed');
    }
    return res.data as ICoach[];
}

const getCoachingRequests = async (myUserId: number) => {
    const res = await axiosApi.get(`/api/CoachingRequests`, getAuthHeader());
    if (!res.data) {
        throw new Error('getting current users coaches failed');
    }
    return res.data as ICoachingRequest[];
}

const sendCoachingRequest = async (email: string) => {
    const res = await axiosApi.post(`/api/CoachingRequests`, {email}, getAuthHeader());
    return res.status === 200;
}

const acceptCoachingRequest = async (requestId: number) => {
    const res = await axiosApi.put(`/api/CoachingRequests/${requestId}`, {}, getAuthHeader());
    return res.status === 200;
}

const deleteCoachingRequest = async (requestId: number) => {
    const res = await axiosApi.delete(`/api/CoachingRequests/${requestId}`, getAuthHeader());
    return res.status === 200;
}

const deleteCoach = async (athleteId: number, coachId: number) => {
    const res = await axiosApi.delete(`/api/athletes/${athleteId}/coaches/${coachId}`, getAuthHeader());
    return res.status === 200;
}

const uploadProfilePicture = async (userId: number, f: File) => {
    const formData = new FormData();
    formData.append("file", f);
    const res = await axiosApi.post(`/api/Users/${userId}/profilePicture`, formData, {
        headers: {
            ...getAuthHeader().headers,
            "Content-Type": "multipart/form-data",
            "Content-Disposition": `form-data; name="${f.name}"`,
        },
    });
    return res.status === 200;
}

const updateUserRequest = async (userId: number, updateData: any) => {
    const res = await axiosApi.put(`/api/Users/${userId}`, updateData, getAuthHeader());
    return res.status === 200;
}

const updateTrainingCommon = async (trainingCommonId: number, data: {
    reportedProblemResolved: boolean
}) => {
    const res = await axiosApi.put(`/api/training-commons/${trainingCommonId}`, data);
    return res.status === 200;
}

const accountDelete = async (userId: number) => {
    const res = await axiosApi.delete(`/api/Users/${userId}`, getAuthHeader());
    return res.status === 200;
}

const setTrainingNote = async (trainingId: number, notes: string) => {
    const res = await axiosApi.put(`/api/Trainings/${trainingId}`, {notes}, getAuthHeader());
    return res.status === 200;
}

const exportTrainingCommon = async (trainingCommonId: number, format: string, slice?: number[], trainingIndexes?: number[]) => {
    const resDetail = await axiosApi.get(`/api/training-commons/${trainingCommonId}/export`, {
        responseType: 'arraybuffer',
        params: {
            format,
            slice,
            trainingIndexes
        }
    });
    if (!resDetail.data) {
        throw new Error('training-common-export-failed');
    }
    return resDetail.data;
}

async function loginPassword(email: string, password: string) {
    const formData = new URLSearchParams();
    formData.append("grant_type", "password");
    formData.append("client_id", apiConfig.apiClientId);
    formData.append("client_secret", apiConfig.apiClientSecret);
    formData.append("username", email);
    formData.append("password", password);
    const res = await axiosApi.post<IToken>("/connect/token", formData);
    if (!res.data || !res.data.access_token) {
        throw new Error(ApiEC.LoginFailed);
    }
    return res.data;
}

async function refresh(refreshToken: string) {
    const formData = new URLSearchParams();
    formData.append("grant_type", "refresh_token");
    formData.append("client_id", apiConfig.apiClientId);
    formData.append("client_secret", apiConfig.apiClientSecret);
    formData.append("refresh_token", refreshToken);
    try {
        const res = await axiosApi.post<IToken>("/connect/token", formData);
        if (!res.data || !res.data.access_token) {
            throw new Error(ApiEC.LoginFailed);
        }
        return res.data as IToken;
    } catch (e: any) {
        if (e.response.status === 400 || e.response.status === 401) {
            throw new Error(ApiEC.Logout);
        } else throw e;
    }
}

export const Api = {
    setAuthToken,

    resetPassword,
    resetPasswordRequest,
    fetchAthletes,
    deleteAthlete,
    getStatistics,
    getTrainingCommon,
    setTrainingNote,
    getCoachingRequests,
    sendCoachingRequest,
    acceptCoachingRequest,
    deleteCoachingRequest,
    getMyCoaches,
    deleteCoach,
    uploadProfilePicture,
    updateUserRequest,
    getUser,
    getUserCached,
    accountDelete,
    getUsers,
    getAdminStatisticsTraining,
    getAdminStatisticsUser,
    exportTrainingCommon,
    getTrainings,
    loginPassword,
    refresh,
    getTrainingCommonFile,
    updateTrainingCommon
}
